import React from 'react';
import ModalLayout from '../ModalLayout';
import VariationView from '../VariationView';
import DishModalFooter, { DishModalFooterPropagatedProps } from './DishModalFooter';
import Text from '../../core-components/Text';
import LabelsView from '../LabelsView';
import styles from './DishModal.scss';
import DishModalSpecialRequest from '../DishModalSpecialRequest';
import { Moment } from 'moment-timezone';
import Quantity from '../Quantity';
import dataHooks from '../../data-hooks';
import { scroller } from 'react-scroll';
import {
  DispatchType,
  DisplayableOrderItem,
  extractImageUrl,
  getDisplayablePrice,
  ItemsHash,
  Menu,
  OrderItem,
  UnavailabilityReason,
  VariationPath,
  getDisplayableOrderItem,
  getCatalogDisplayableOrderItem,
  toggleChoice,
  calcOrderItemGrandTotal,
  Catalog,
  CatalogItemsHash,
  CatalogPlatform,
  createCatalogOrderItem,
} from '@wix/restaurants-client-logic';
import { EMPTY_ORDER_ITEM } from '../../../../core/constants';
import { ClickAddItemToCartPayload, SetPendingOrderItemPayload } from '../../../../state/cart/cart.actions.types';
import { TFunction, useBi, VisitorLogger, useExperiments, Experiments } from '@wix/yoshi-flow-editor';
import {
  _hasInvalidVariations,
  _initializeErrorVisibilityObject,
  findSectionById,
  getInvalidVariations,
} from './dishModalUtils';
import { limitationToString } from '../VariationLimitationView/variationLimitationUtils';
import { ThumbnailImage, ImageLoadingBehaviorOptions as LoadingBehaviorOptions, CounterSize } from 'wix-ui-tpa';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import {
  addToCartValidationError,
  dishModalDishQuantitiesUpdate,
  dishModalDiscard,
} from '@wix/bi-logger-olo-client/v2';

export interface DishModalProps extends DishModalFooterPropagatedProps {
  onRequestClose: Function;
  setPendingOrderItem: (payload: SetPendingOrderItemPayload) => void;
  clickAddItemToCart: (payload: ClickAddItemToCartPayload) => void;
  addPendingOrderItemToCart: Function;
  catalogItemsHash: CatalogItemsHash;
  itemsHash: ItemsHash;
  pendingOrderItem: OrderItem | undefined;
  platform: CatalogPlatform;
  showLabels: boolean;
  catalog: Catalog;
  menu: Menu;
  locale: string;
  currency: string;
  dispatchType: DispatchType;
  dispatchTime: Moment;
  isMobile?: boolean;
  history: History<{ originSectionId?: string }>;
  t: TFunction;
  experiments: Experiments;
}

interface DishModalState {
  variationsWithVisibleErrors: Record<string, boolean>;
  pendingOrderItem: OrderItem;
  displayableOrderItem: DisplayableOrderItem;
}

export const getUnavailableText = (reason: string, t: TFunction) => {
  switch (reason) {
    case UnavailabilityReason.SoldOut:
      return t('online_ordering_menuitem_soldout_label');
    case UnavailabilityReason.Unavailable:
      return t('online_ordering_menuitem_unavailable_label');
    default:
      return '';
  }
};

const withBiAndRouter = (Component: any) => (props: DishModalProps) => {
  const biLogger: VisitorLogger = useBi();
  const history: History = useHistory();
  const { experiments } = useExperiments(); // TODO: remove when merging specs.restaurants.catalogs-v3-migration
  return <Component biLogger={biLogger} {...props} history={history} experiments={experiments} />;
};

class DishModal extends React.Component<DishModalProps & { biLogger: VisitorLogger }, DishModalState> {
  displayName = 'DishModal';
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  tmp: number;

  constructor(props: DishModalProps & { biLogger: VisitorLogger }) {
    super(props);
    const { experiments, catalogItemsHash } = this.props;
    const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');

    const pendingOrderItem: OrderItem = props.pendingOrderItem || EMPTY_ORDER_ITEM;

    const orderItem: OrderItem = isCatalogsV3
      ? createCatalogOrderItem({
          itemId: pendingOrderItem.itemId,
          itemsHash: catalogItemsHash,
        })
      : pendingOrderItem;

    this.state = {
      variationsWithVisibleErrors: {},
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(orderItem),
    };

    this.scrollContainerRef = React.createRef();

    this.tmp = Math.random();
  }

  getDisplayableOrderItem(orderItem: OrderItem) {
    const {
      catalog,
      catalogItemsHash,
      menu,
      itemsHash,
      locale,
      dispatchTime,
      platform,
      currency,
      dispatchType,
      experiments,
    } = this.props;
    const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');

    const sectionHint = findSectionById(menu, this.props.history.location?.state?.originSectionId);

    if (isCatalogsV3) {
      return getCatalogDisplayableOrderItem({
        catalog,
        orderItem,
        itemsHash: catalogItemsHash,
        locale,
        dispatchTime,
        platform,
        currency,
        dispatchType,
        sectionHint: this.props.history.location?.state?.originSectionId,
      });
    } else {
      return getDisplayableOrderItem({
        menu,
        orderItem,
        itemsHash,
        locale,
        dispatchTime,
        platform: platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
        currency,
        dispatchType,
        sectionHint,
      });
    }
  }

  _scrollToFirstError = (variationsWithVisibleErrors: Record<string, boolean>) => {
    const options = {
      container: this.scrollContainerRef.current,
      smooth: 'easeInOutCubic',
      duration: 200,
      offset: -75, // this is needed because the modal parallax image causes the scroll to not be completely accurate
    };

    const firstVariationWithErrors = Object.keys(variationsWithVisibleErrors)[0];
    if (firstVariationWithErrors) {
      scroller.scrollTo(firstVariationWithErrors, options);
    }
  };

  _handleSubmitButtonClick = () => {
    const { onRequestClose, setPendingOrderItem, addPendingOrderItemToCart, t, clickAddItemToCart } = this.props;
    const { pendingOrderItem, displayableOrderItem } = this.state;

    const hasInvalidVariations = _hasInvalidVariations(displayableOrderItem);

    if (hasInvalidVariations) {
      const variationsWithVisibleErrors = _initializeErrorVisibilityObject(displayableOrderItem.variations);
      const invalidVariations = getInvalidVariations(displayableOrderItem).map((variation) =>
        limitationToString(t, variation.limitation, variation.min, variation.max),
      );
      this.props.biLogger.report(
        addToCartValidationError({
          dishId: pendingOrderItem.itemId,
          errorsCount: invalidVariations.length,
          errorReason: invalidVariations.join('&'),
        }),
      );
      this.setState({ variationsWithVisibleErrors }, () => {
        this._scrollToFirstError(variationsWithVisibleErrors);
      });
    } else {
      onRequestClose();
      setPendingOrderItem({ orderItem: pendingOrderItem });
      clickAddItemToCart({ orderItem: pendingOrderItem });
      addPendingOrderItemToCart();
    }
  };

  _handleVariationChange = (variationPath: VariationPath, choiceId: string, clientSideId: string) => {
    const variationsWithVisibleErrors = {
      ...this.state.variationsWithVisibleErrors,
      [clientSideId]: false,
    };

    const pendingOrderItem = toggleChoice({
      orderItem: this.state.pendingOrderItem,
      itemsHash: this.props.itemsHash,
      variationPath,
      choiceId,
    });

    this.setState({
      variationsWithVisibleErrors,
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  _handleQuantityChange = (quantity: number) => {
    const currentQuantity = this.state.displayableOrderItem.quantity;
    const action = quantity > currentQuantity ? 'increase' : 'decrease';
    this.props.biLogger.report(
      dishModalDishQuantitiesUpdate({
        action,
        dishId: this.state.displayableOrderItem.id,
        quantity,
      }),
    );

    const pendingOrderItem: OrderItem = {
      ...this.state.pendingOrderItem,
      count: quantity,
    };

    this.setState({
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  _handleCommentChange = (comment: string) => {
    const pendingOrderItem: OrderItem = {
      ...this.state.pendingOrderItem,
      comment,
    };

    this.setState({
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  renderAvailability = (text: string) => (
    <div className={styles.subTitle}>
      <Text data-hook={dataHooks.dishModalUnavailableDishText} typography="p2-l-60">
        {text}
      </Text>
    </div>
  );

  getPriceDifference = () => {
    const { locale, currency, pendingOrderItem: originalOrderItem } = this.props;
    const { pendingOrderItem } = this.state;

    return getDisplayablePrice(
      calcOrderItemGrandTotal(pendingOrderItem) - calcOrderItemGrandTotal(originalOrderItem || EMPTY_ORDER_ITEM),
      locale,
      currency,
    );
  };

  render() {
    const { itemsHash, onRequestClose, showLabels, biLogger, isMobile, t, currency } = this.props;
    const { pendingOrderItem, displayableOrderItem } = this.state;
    const { variationsWithVisibleErrors } = this.state;
    const imageUrl = pendingOrderItem && extractImageUrl(itemsHash[pendingOrderItem.itemId]);
    const footer = (
      <DishModalFooter
        isEditingItemFromTheCart={this.props.isEditingItemFromTheCart}
        priceDifference={this.getPriceDifference()}
        onSubmitButtonClick={this._handleSubmitButtonClick}
        displayableOrderItem={displayableOrderItem}
        onQuantityChange={this._handleQuantityChange}
        currency={currency}
      />
    );

    // Deliberately using an empty string as alt text.
    // In this particular case adding the dish title/description will not have any effect,
    // since this data is shown directly under the image, and we do not want screen reader to read it twice.
    const alt = '';

    return (
      <ModalLayout
        onCloseClick={() => {
          biLogger.report(dishModalDiscard({ dishId: pendingOrderItem.itemId }));
          onRequestClose();
        }}
        data-hook={dataHooks.dishModal}
        parallax={
          imageUrl && (
            <ThumbnailImage
              src={imageUrl}
              width={isMobile ? 400 : 560}
              height={isMobile ? 220 : 420}
              alt={alt}
              loadingBehavior={LoadingBehaviorOptions.blur}
              fluid
            />
          )
        }
        header={displayableOrderItem.title}
        footer={footer}
        scrollContainerRef={this.scrollContainerRef}
      >
        {showLabels && <LabelsView labels={displayableOrderItem.labels} />}
        {displayableOrderItem.errors[0]?.type === 'order_delivery_time' &&
          this.renderAvailability(getUnavailableText(displayableOrderItem.errors[0].reason, t))}
        {displayableOrderItem.subTitle && (
          <Text typography="p2-m" className={styles.subTitle} id="modal-description">
            {displayableOrderItem.subTitle}
          </Text>
        )}
        <div className={styles.spacer20} />
        {displayableOrderItem.variations.map((displayableVariation) => {
          return (
            <VariationView
              key={displayableVariation.clientSideId}
              displayableVariation={displayableVariation}
              onChange={this._handleVariationChange}
              variationsWithVisibleErrors={variationsWithVisibleErrors}
              shouldDisplayError={variationsWithVisibleErrors[displayableVariation.clientSideId]}
              currency={this.props.currency}
            />
          );
        })}
        {!pendingOrderItem.hideSpecialRequest && (
          <DishModalSpecialRequest
            value={pendingOrderItem?.comment}
            onChange={this._handleCommentChange}
            disabled={displayableOrderItem.submitDisabled}
          />
        )}
        <div className={styles.quantity}>
          <Text typography="p2-m" className={styles.subTitle}>
            {t('DISH_SCREEN_QUANTITY')}
          </Text>

          <Quantity
            size={CounterSize.xSmall}
            data-hook={dataHooks.dishModalQuantity}
            value={displayableOrderItem.quantity}
            onChange={(val) => {
              const n = Number(val);
              n >= 1 && n <= 999 && this._handleQuantityChange(n);
            }}
            aria-label={t('DISH_SCREEN_SCREENREADER_QTYSELECTED_TEXT')}
            inputAriaLabel={t('DISH_SCREEN_QUANTITY')}
            incrementAriaLabel={t('DISH_SCREEN_SCREENREADER_INCREASE_TEXT')}
            decrementAriaLabel={t('DISH_SCREEN_SCREENREADER_DECREASE_TEXT')}
            min={1}
            step={1}
            disabled={displayableOrderItem.submitDisabled}
            halfWidth
          />
        </div>
      </ModalLayout>
    );
  }
}

export default withBiAndRouter(DishModal);
